<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="home" :query="{}"> </Mural>
    <div class="list-training q-mt-md">
      <TestsCard
        v-for="(training, index) in testsList"
        :key="training.id"
        :ligthingTestData="training"
        :image="bannerImg"
        @delete:test="deleteTest(index)"
      />
    </div>
  </div>
</template>

<script>
//-----IMAGES
import bannerImg from "@/assets/images/banner_default.png";

//----SERVICES
import BreadcrumbsDataServices from "@/services/BreadcrumbsDataServices";
import PostDataServices from "@/services/PostDataServices";

//----COMPONENTS
import Mural from "@/components/Mural.vue";
import TestsCard from "@/components/exam/cards/TestsCard.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";

//----UTILS
import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----VUEJS
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  name: "ListExamPage",
  components: {
    Mural,
    TestsCard,
    DefaultBtn,
  },
  setup() {
    const route = new useRoute();
    const breadcrumbs = ref(["Teste Relâmpago"]);
    const url_aws_bucket = ENV.URL_AWS_BUCKET;
    const breadcrumbsDataServices = new BreadcrumbsDataServices();
    const postDataServices = new PostDataServices();

    let testsList = ref([]);
    let training_count = ref(0);
    //let courseId = route.query.id;
    let btnAddTraining = ref(true);
    let canUserAccessClass = new CanUserAccessClass();

    onMounted(() => {
      ValidateActionsPage();
      getAllTraining();
    });

    async function getAllTraining() {
      // testsList.value = [
      //   {
      //     id: 2,
      //     name: "teste anme",
      //     workload_hour: 30,
      //     title: "teste title"
      //   }
      // ]
      await postDataServices
        .getAllLightningTest()
        .then((reponse) => {
          testsList.value = reponse.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function deleteTest(index) {
      testsList.value.splice(index, 1);
    }

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-training", false)) {
        btnAddTraining.value = false;
        return;
      }
    }

    return {
      //----IMAGES
      bannerImg,

      testsList,
      //courseId,
      btnAddTraining,
      training_count,
      url_aws_bucket,
      breadcrumbs,
      deleteTest,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .list-training {
    overflow: auto;
    height: 75vh;
    padding: 1%;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
