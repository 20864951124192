<template>
  <div class="containt-training">
    <div class="training row col-12 q-mt-lg">
      <div class="col-12">
        <div class="card-training row col-12">
          <div class="col-md-4 col-sm-12">
            <q-img :src="image" class="image-training" height="120px">
              <div class="absolute-bottom-left section-button-images">
                <RoundedBtn
                  titleButton="Continuar"
                  route="lighting-test-answer"
                  :query="{
                    id: ligthingTestData.id,
                    concluded: ligthingTestData.concluded,
                  }"
                />
              </div>
            </q-img>
          </div>
          <div class="description-training col-md-8 col-sm-12 q-pt-sm">
            <div class="header-description-training row flex col-12 q-pl-sm">
              <div class="header-icon flex items-center col-8">
                <div class="q-mr-sm col-3">
                  <Badge iconBadge="book" typeBadge="Conhecimento" />
                </div>
                <!-- <q-avatar size="40px" class="q-mr-sm">
                  <img :src="trainingImg" />
                </q-avatar> -->
                <label>{{ ligthingTestData.title }}</label>
              </div>
              <div class="btn-header-edition flex justify-end col-4">
                <q-btn
                  round
                  dense
                  size="md"
                  color="default-pink"
                  v-show="btnEditTraning"
                  :to="{
                    name: 'lighting-test',
                    query: { id: ligthingTestData.id },
                  }"
                >
                  <q-img width="70%" :src="editImg" spinner-color="white" />
                </q-btn>
                <q-btn
                  class="q-ml-md"
                  color="default-pink"
                  icon="o_delete_outline"
                  round
                  v-show="btnEditTraning"
                  dense
                  size="md"
                  @click="deleteTest(ligthingTestData.id)"
                />
              </div>
              <label v-if="ligthingTestData.concluded" class="q-px-xl">
                você ja finalizou esse teste
              </label>
            </div>
            <div class="body-description-training">
              <div class="list-info row items-center col-12">
                <div class="col-6 icon-info-trilha q-py-sm">
                  <!-- <q-img
                    width="1.5rem"
                    class="q-mr-md"
                    :src="clockImg"
                    spinner-color="white"
                    title="Carga horária"
                  /> -->
                  <Clock
                    :width="22"
                    :height="22"
                    class="q-mr-sm"
                    color="var(--q-secundary)"
                  />
                  <label>{{ convertDate(ligthingTestData.start_date) }}</label>
                </div>
                <div class="col-6 icon-info-trilha">
                  <!-- <q-img
                    width="1.5rem"
                    class="q-mr-md"
                    :src="chalkboardImg"
                    spinner-color="white"
                    title="Tutor"
                  /> -->
                  <Teacher
                    :width="22"
                    :height="22"
                    class="q-mr-sm"
                    color="var(--q-secundary)"
                  />
                  <label
                    >{{ ligthingTestData.first_name }}
                    {{ ligthingTestData.last_name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
//----IMAGES
import trainingImg from "@/assets/icons/training-icon.svg";
import editImg from "@/assets/icons/edit.svg";
import clockImg from "@/assets/icons/clock.svg";
import chalkboardImg from "@/assets/icons/chalkboard-teacher.svg";
import folderImg from "@/assets/icons/folder-tree.svg";
import multipleImg from "@/assets/icons/multiple-user.svg";

import Teacher from "@/assets/icons/custom/Teacher.vue";
import Clock from "@/assets/icons/custom/Clock.vue";

//----COMPONENTS
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import Badge from "@/components/BadgeType.vue";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----QUASAR
import { useQuasar, date } from "quasar";
import { useRouter } from "vue-router";

//----SERVICES
import PostDataServices from "@/services/PostDataServices";

//----VUEJS
import { onMounted, ref } from "vue";

export default {
  name: "TestsCard",
  emits: ["delete:test"],
  components: {
    RoundedBtn,
    Badge,
    Teacher,
    Clock,
  },
  props: {
    ligthingTestData: Object,
    image: String,
  },
  setup(props, { emit }) {
    let $q = new useQuasar();
    const postDataServices = new PostDataServices();

    let router = new useRouter();
    let loading = ref(false);
    let participate =
      !props.ligthingTestData.status_user &&
      props.ligthingTestData.training_finish == null;
    let available =
      props.ligthingTestData.status_user &&
      props.ligthingTestData.training_finish == null;
    let finish =
      props.ligthingTestData.status_user &&
      props.ligthingTestData.training_finish != null;
    let canUserAccessClass = new CanUserAccessClass();
    let btnEditTraning = ref(true);

    onMounted(() => {
      ValidateActionsPage();
    });

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("edit-training", false)) {
        btnEditTraning.value = false;
        return;
      }
    }

    function convertDate(date_created) {
      return date.formatDate(date_created, "DD/MM/YYYY");
    }

    function deleteTest(id) {
      $q.notify({
        message: "Você deseja apagar esse humor?",
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "Sim!",
            color: "yellow",
            handler: async () => {
              await postDataServices
                .deleteLightningTestById(id)
                .then((response) => {
                  if (response.data) {
                    emit("delete:test");
                  }
                })
                .catch((error) => {
                  _alertOf(error.response.data.toString());
                });
            },
          },
          { label: "Não!", color: "white" },
        ],
      });
    }

    async function participateTraining() {
      // loading.value = true;
      // let courseId = props.ligthingTestData.course_id;
      // let trainingId = props.ligthingTestData.id;
      // let postId = props.ligthingTestData.post_id;
      // await http.post(`v1/course/${courseId}/training/${trainingId}/enter-as-student`).then((response) => {
      //       loading.value = false;
      //       router.push({ name: "training-page", query: { courseId: courseId, trainingId: trainingId, postId: postId } });
      //    }).catch((error) => {
      //       _alertOf(error.response.data.toString());
      //        loading.value = false;
      //    });
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      //----IMAGES
      trainingImg,
      editImg,
      clockImg,
      chalkboardImg,
      folderImg,
      multipleImg,

      //------------
      //minute,
      loading,
      participate,
      btnEditTraning,
      available,
      finish,
      participateTraining,
      convertDate,
      deleteTest,
    };
  },
};
</script>

<style lang="scss" scoped>
.containt-training {
  border-radius: 20px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
}
.training {
  padding: 1%;
  background: #fdfdfd;
  border-radius: 24px;

  .card-training {
    flex-direction: column;
    @media (min-width: 800px) {
      flex-direction: row;
    }
  }
}

.image-training {
  border-radius: 10px;
}

.section-button-images {
  border-radius: 20px;
  padding: 3px !important;
  margin: 0 0 1% 1%;
  width: 140px;

  background: none;

  /* .button-image {
    background-color: #ffffff;
  } */
}

.participate {
  background-color: #fa6b1f;

  .button-image {
    color: #fa6b1f;
  }
}
.continue {
  background-color: var(--q-primary, #fe4e64);
  .button-image {
    color: var(--q-primary, #fe4e64);
  }
}
.finish {
  background-color: #7cae44;
  .button-image {
    color: #7cae44;
  }
}

.description-training {
  .header-description-training {
    .btn-header-edition {
      height: 33%;
    }
    .header-icon {
      & > div {
        width: 45px;
        height: 45px;
      }

      label {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }
  }

  .body-description-training {
    padding: 2%;

    .icon-info-trilha {
      display: flex;
      align-items: flex-end;
    }
  }
}

.image-training {
  img {
    border-radius: 24px;
  }
}
</style>
